import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { motion, useAnimate } from "framer-motion"
import ActionForm from './../components/ActionForm';

import { ReactSession } from 'react-client-session';


var parseOnce=false;
function Careers(props) {


  var [render, setRender] = useState(0);

   ReactSession.setStoreType("localStorage");


   //ReactSession.set("next_url", errors[0].next_url);

  useEffect(() => {


    if(!parseOnce) {
      parseOnce=true;
      const queryParams = new URLSearchParams(window.location.search);
    }
    


  });


  

  return (
    <div className="page">
    <div className="vector_truck">
      <h1>Careers</h1><br/><br/>
     </div>
     <div className="white_block">
     <h1>Class 1A Drivers</h1>
     <p>Action Express is always looking for both Class 1A professional drivers, and drivers of all classes with clean abstracts interested in making a good living in Canada doing coast-to-coast and water-to-water driving.</p>
     <br/>

    <h4>Apply to Drive Online:</h4><br/>
      <Button variant="primary" size="lg" onClick={()=>{window.open("/driver_application", "_self")}}>Driver Application</Button><br/><br/>

      <h4>Or deliver your resume and cover letter to:</h4><br/>

      Action Express Ltd.<br/>
      836 Fleury Street<br/>
      Regina, SK<br/>
      S4N 4W6
     </div>
    </div>);

}
  
export default Careers;