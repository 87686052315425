import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import * as vars from './../vars.js';

import { ReactSession } from 'react-client-session';

var cats = [];
for(var i=0;i<vars.pages.length;i++) {
  var page = vars.pages[i];
  if(!cats[page.category]) {
    cats[page.category]=[];
  }
  cats[page.category].push(page);
}


function Footer(props) {

 const [categories, setCategories] = useState([]);


  useEffect(() => {
    
    //console.log(cats);
    
    setCategories(cats);
    
    
  });

  function clicked(name) {



  }


  return (<>
  <div className="footer_box">

    <div className="full_footer">
      
      {Object.keys(categories).map((category, index) => {
          if(category!='false') {
            return (<div key={index} className={"list left_list_"+index}><ul>
                    <li><span className="list_head"><a className="white_link" href={(cats[category][0].name==category?cats[category][0].href:undefined)}>{category}</a></span></li>

                    {vars.pages.map((page, index) => {
                       if(page.footer && page.category == category && page.name != category && ((props.account && page.logged_in_only) || (!props.account && page.logged_out_only) || (!page.logged_in_only && !page.logged_out_only))) {
                        return (<li key={index}><a className="white_link" onClick={()=>clicked(page.name)} href={page.href}>{page.name}</a></li>);
                      }
                    })}
                  </ul></div>);
          }
          
        })}

      
      <div className="footer_address">
      Action Express Ltd.<br/>
      836 Fleury Street<br/>
      Regina, SK<br/>
      S4N 4W6<br/><br/>
      <span className="footer_phone"><a className="white_link" href="tel://13067895055">306.789.5055</a></span><br/>
      <span className="footer_email"><br/>info@actionexpress.co</span>
      </div>
      <div className="footer_logo">
      </div>
    </div>
  

    <div className="mobile_footer">

      <img style={{cursor: 'pointer'}} src="/images/small_logo.png" onClick={()=>{window.open("/", '_self')}} width="100" /><br/><br/>
        
        <span className="less_margin"><h3>Action Express Ltd.</h3></span>
        <h6>836 Fleury Street, Regina, SK - S4N 4W6</h6><br/>
        
        <h6><a className="white_link footer_phone" href="tel://13067895055">1.306.789.5055</a></h6>
        <h6><a className="white_link footer_email">info@actionexpress.co</a></h6><br/>
        <a href="https://twitter.com/ActionExpressCo"><img className="social_img" src="/images/twitter.png" width="35" /></a>
        <a href="https://www.facebook.com/ActionExpressLtd"><img className="social_img" src="/images/facebook.png" width="35" /></a>
        <br/>
        <br/>

        <div className="no-print">
        {Object.keys(categories).map((category, index) => {
          if(category!='false') {
            return (<div key={index} className={"mobile_list mobile_footer_list_"+index}><ul>
                    <div className="list_head"><a className="white_link" href={(cats[category][0].name==category?cats[category][0].href:undefined)}>{category}</a></div>
                    {vars.pages.map((page, index) => {
                      if(page.footer && page.category == category && page.name != category && ((props.account && page.logged_in_only) || (!props.account && page.logged_out_only) || (!page.logged_in_only && !page.logged_out_only))) {
                      
                        return (<li key={index}><a className="white_link"  onClick={()=>clicked(page.name)} href={page.href}>{page.name}</a></li>);
                      }
                    })}
                  </ul></div>);
          }
          
        })}
        
        </div>

    

    </div>
  </div>

  <div className="copyright">Copyright 2023, Action Express Ltd.</div>
  <br/><br/>

  </>);

}
  
export default Footer;