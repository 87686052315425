import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { motion, useAnimate } from "framer-motion"
import ActionForm from './../components/ActionForm';

import { ReactSession } from 'react-client-session';


var parseOnce=false;
function DriverApplication(props) {


  var [render, setRender] = useState(0);

   ReactSession.setStoreType("localStorage");


   //ReactSession.set("next_url", errors[0].next_url);

  useEffect(() => {


    if(!parseOnce) {
      parseOnce=true;
      const queryParams = new URLSearchParams(window.location.search);
    }
    


  });


  

  return (
    <div className="page">
     <div className="white_block">
     <h1>Driver Application</h1>
     <br/><br/>
      <ActionForm theme="dark" 
              nextReason="To apply online"
              submitLabel="Apply Online" submitAction={"/careers/apply"}
              backLabel="" backAction="" 
              buttonSize="lg" 
              fields={[
                { type: 'text', name: 'first_name', label: 'First Name',  placeholder: 'John', defaultValue: '', readOnly: false},
                { type: 'text', name: 'last_name', label: 'Last Name',  placeholder: 'Smith', defaultValue: '', readOnly: false},
                { type: 'email', name: 'email_address', label: 'Email Address',  placeholder: 'john.smith@gmail.com', defaultValue: '', readOnly: false},
                { type: 'text', name: 'phone_number', label: 'Phone Number',  placeholder: '306.123.4567', defaultValue: '', readOnly: false},
                { type: 'textarea', name: 'additional_info', label: 'Additional Information',  placeholder: '', defaultValue: '', readOnly: false, textareaArgs: {rows: 3}},
                // { type: 'password', name: 'password', label: 'Password',  placeholder: '******', defaultValue: '', readOnly: false},
                 { type: 'file', name: 'resume', label: 'Resume',  placeholder: 'John', defaultValue: '', readOnly: false},
                { type: 'hidden', name: 'redirect_url', defaultValue: ReactSession.get("next_url"), readOnly: true}
              ]} />

      <br/><br/>

      
     </div>
    </div>);

}
  
export default DriverApplication;