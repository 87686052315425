import React, {useState, useEffect} from 'react';

import ActionForm from './components/ActionForm';

import Header from './components/Header';
import Footer from './components/Footer';

import Quote from './components/Quote';
import DriverApplication from './components/DriverApplication';
import Message from './components/Message';
import Home from './components/Home';
import About from './components/About';
import Fleet from './components/Fleet';

import Service from './components/Service';

import Services from './components/Services';
import Contact from './components/Contact';
import Careers from './components/Careers';

import logo from './logo.svg';
import './App.css';

import * as vars from './vars.js';
import * as funcs from './funcs.js';

import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";



import { ReactSession } from 'react-client-session';

var doOnce=false;

function App() {


  const [render, setRender] = useState(0);


 


  useEffect(() => {

  //console.log('window.location.search: '+window.location.search);

    (async function() {

      if(!doOnce) {
        doOnce=true;

       
        setRender(render+1);

      }
    })();

    
    
  });

  return (
    <div className="center_site">
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/message/:obj" element={<><Message /></>} />
          <Route path="/" element={<><Home /></>} />
          <Route path="/about" element={<><About /></>} />
          <Route path="/fleet" element={<><Fleet /></>} />
          <Route path="/services" element={<><Services /></>} />

          <Route path="/services/:service_id" element={<><Service /></>} />

          <Route path="/contact" element={<><Contact /></>} />
          <Route path="/careers" element={<><Careers /></>} />
          <Route path="/driver_application" element={<><DriverApplication /></>} />
          <Route path="/quote" element={<><Quote /></>} />
          <Route path="/quote/:prefill_id" element={<><Quote /></>} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );


  
}

export default App;
