import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";

import { ReactSession } from 'react-client-session';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import * as vars from './../vars.js';
import * as funcs from './../funcs.js';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, subDays, getDay } from 'date-fns';


var serialize = require('form-serialize');

var parseOnce=false;

var doOnce=false;

var fileInputIds = [];

function ActionForm(props) {


  const [errors, setErrors] = useState([]);


  var renderFieldsCount = 0;

  ReactSession.setStoreType("localStorage");

  async function postForm() {

    var buttonHtml = document.getElementById('actionform_'+props.id+'_submit').innerHTML;

    document.getElementById('actionform_'+props.id+'_submit').innerHTML = 'Submitting...';

    var obj = serialize(document.getElementById('actionform_'+props.id), { hash: true, disabled: true, empty: true });

    console.log(obj);

    var filesObj={};
    for(var i=0;i<fileInputIds.length;i++) {
      var anId = fileInputIds[i];
      var fileField = document.getElementById(anId);


      if(fileField.files[0]) {
        //console.log(fileField.files[0]);
        const buffer = await fileField.files[0].arrayBuffer(); //just grab first file selected
        // each entry of array should contain 8 bits
        const bytes = new Uint8Array(buffer);
        filesObj[fileField.files[0].name] = { bytes: bytes, size: fileField.files[0].size };
      }
      
    }

   
    obj.filesObj = filesObj;

    var data;
    if(props.method=='get') {
      data = await funcs.get(props.submitAction, obj, (errors)=>{ errFunc(errors); });
    } else {
      data = await funcs.post(props.submitAction, obj, (errors)=>{ errFunc(errors); });
    }

    if(props.submitted) {
      props.submitted();
    }
    

    // Handle these errors locally or proceed...
    if(!data.errors) {
      window.open(data.redirect_url, '_self');
    }

    document.getElementById('actionform_'+props.id+'_submit').innerHTML = buttonHtml;

  }


  async function errFunc(errors) {
    setErrors(errors);
    window.open('#first_error', '_self');
    
    if(errors[0].code=='INVALID_TOKEN') {
      ReactSession.set("next_reason", props.nextReason);
      ReactSession.set("next_url", errors[0].next_url);
    }

    for(var i=0;i<errors.length;i++) {
      if(errors[i].redirect_url) {
        window.open(errors[0].redirect_url, '_self');
      }
    }
  }


  function hidden(key, type, name, label, mutedText, mutedLink, placeholder, defaultValue, readOnly, disabled, dateArgs, selectOpts, textareaArgs, changed) {

    var error=false;
    for(var i=0;i<errors.length;i++) {
      if(errors[i].field==name) {
        error = errors[i];
        break;
      }
    }


    return(<div key={"form"+type+key}>
    <Form.Group className="mb-3" controlId={"controlForm"+type+key}>
      <Form.Control className={(readOnly?'readonly':'')+' '+props.theme} readOnly={readOnly} name={name} type="hidden" placeholder={placeholder} defaultValue={defaultValue} />
      <Form.Text className="text-muted">
        {mutedText} <a href={mutedLink?mutedLink.href:''}>{mutedLink?mutedLink.label:''}</a> <span className="form_error_message">{error?error.description:''}</span>
      </Form.Text>
    </Form.Group>
    </div>);

  }

  function password(key, type, name, label, mutedText, mutedLink, placeholder, defaultValue, readOnly, disabled, dateArgs, selectOpts, textareaArgs, changed) {

    var error=false;
    for(var i=0;i<errors.length;i++) {
      if(errors[i].field==name) {
        error = errors[i];
        break;
      }
    }

    return(<div key={"form"+type+key}>
    <Form.Group className="mb-3" controlId={"controlForm"+type+key}>
      <Form.Label>{label}</Form.Label>
      <Form.Control className={(readOnly?'readonly':'')+' '+props.theme+' text'} readOnly={readOnly} name={name} type="password" placeholder={placeholder} defaultValue={defaultValue} />
      <Form.Text className="text-muted">
        {mutedText} <a href={mutedLink?mutedLink.href:''}>{mutedLink?mutedLink.label:''}</a> <span className="form_error_message">{error?error.description:''}</span>
      </Form.Text>
    </Form.Group>
    </div>);

  }

  function text(key, type, name, label, mutedText, mutedLink, placeholder, defaultValue, readOnly, disabled, dateArgs, selectOpts, textareaArgs, changed) {

    var error=false;
    for(var i=0;i<errors.length;i++) {
      if(errors[i].field==name) {
        error = errors[i];
        break;
      }
    }


    return(<div key={"form"+type+key}>
    <Form.Group className="mb-3" controlId={"controlForm"+type+key}>
      <Form.Label>{label}</Form.Label>
      <Form.Control className={(readOnly?'readonly':'')+' '+props.theme+' text'} readOnly={readOnly} name={name} type="text" placeholder={placeholder} defaultValue={defaultValue} />
      <Form.Text className="text-muted">
        {mutedText} <a href={mutedLink?mutedLink.href:''}>{mutedLink?mutedLink.label:''}</a> <span className="form_error_message">{error?error.description:''}</span>
      </Form.Text>
    </Form.Group>
    </div>);

  }


  function file(key, type, name, label, mutedText, mutedLink, placeholder, defaultValue, readOnly, disabled, dateArgs, selectOpts, textareaArgs, changed) {

    var error=false;
    for(var i=0;i<errors.length;i++) {
      if(errors[i].field==name) {
        error = errors[i];
        break;
      }
    }

    fileInputIds.push("controlForm"+type+key);


    return(<div key={"form"+type+key}>
    <Form.Group className="mb-3" controlId={"controlForm"+type+key}>
      <Form.Label>{label}</Form.Label>
      <Form.Control className={(readOnly?'readonly':'')+' '+props.theme+' text'} readOnly={readOnly} name={name} type="file" placeholder={placeholder} defaultValue={defaultValue} />
      <Form.Text className="text-muted">
        {mutedText} <a href={mutedLink?mutedLink.href:''}>{mutedLink?mutedLink.label:''}</a> <span className="form_error_message">{error?error.description:''}</span>
      </Form.Text>
    </Form.Group>
    </div>);

  }


  function email(key, type, name, label, mutedText, mutedLink, placeholder, defaultValue, readOnly, disabled, dateArgs, selectOpts, textareaArgs, changed) {

    var error=false;
    for(var i=0;i<errors.length;i++) {
      if(errors[i].field==name) {
        error = errors[i];
        break;
      }
    }


    return(<div key={"form"+type+key}>
    <Form.Group className="mb-3" controlId={"controlForm"+type+key}>
      <Form.Label>{label}</Form.Label>
      <Form.Control className={props.theme+' text'} name={name} type="email" placeholder={placeholder} defaultValue={defaultValue} />
      <Form.Text className="text-muted">
        {mutedText} <a href={mutedLink?mutedLink.href:''}>{mutedLink?mutedLink.label:''}</a> <span className="form_error_message">{error?error.description:''}</span>
      </Form.Text>
    </Form.Group>
    </div>);
  }

  function date(key, type, name, label, mutedText, mutedLink, placeholder, defaultValue, readOnly, disabled, dateArgs, selectOpts, textareaArgs, changed) {

    var error=false;
    for(var i=0;i<errors.length;i++) {
      if(errors[i].field==name) {
        error = errors[i];
        break;
      }
    }


    return(<div key={"form"+type+key}>
    <Form.Group className="mb-3" controlId={"controlForm"+type+key}>
      <Form.Label>{label}</Form.Label><br/>
      <DatePicker 
      name={name} 
      excludeDates={dateArgs.excludeDates}
      filterDate={dateArgs.filterDate}
      selected={dateArgs.startDate} 
      onChange={(date:Date) => dateArgs.onChange(date)}  />
      </Form.Group>
      <Form.Text className="text-muted">
        {mutedText} <a href={mutedLink?mutedLink.href:''}>{mutedLink?mutedLink.label:''}</a> <span className="form_error_message">{error?error.description:''}</span>
      </Form.Text>
    </div>);
  }

  function spacer(key, type, name, label, mutedText, mutedLink, placeholder, defaultValue, readOnly, disabled, dateArgs, selectOpts, textareaArgs, changed) {
    return (<div key={"form"+type+key}><br/><br/><h3>{label}&nbsp;</h3></div>);
  }
  function spacer_short(key, type, name, label, mutedText, mutedLink, placeholder, defaultValue, readOnly, disabled, dateArgs, selectOpts, textareaArgs, changed) {
    return (<div className="spacer_short" key={"form"+type+key}>{label}&nbsp;</div>);
  }

  function select(key, type, name, label, mutedText, mutedLink, placeholder, defaultValue, readOnly, disabled, dateArgs, selectOpts, textareaArgs, changed) {

    var error=false;
    for(var i=0;i<errors.length;i++) {
      if(errors[i].field==name) {
        error = errors[i];
        break;
      }
    }


    return(<div key={"form"+type+key}>
      <Form.Group className="mb-3" controlId={"controlForm"+type+key}>
        <Form.Label>{label}</Form.Label>
        <select onChange={()=>{
          changed(name);
        }}  disabled={disabled} defaultValue={defaultValue} name={name} className={"form-control "+props.theme+' select'} id={"select"+key}>
          {selectOpts.map((option, optKey) => {
            return <option value={option.value} key={optKey}>{option.label}</option>;
          })}
        </select>
        <Form.Text className="text-muted">
        {mutedText} <a href={mutedLink?mutedLink.href:''}>{mutedLink?mutedLink.label:''}</a> <span className="form_error_message">{error?error.description:''}</span>
        
      </Form.Text>
      </Form.Group>
    </div>);
  }

  function textarea(key, type, name, label, mutedText, mutedLink, placeholder, defaultValue, readOnly, disabled, dateArgs, selectOpts, textareaArgs, changed) {

    var error=false;
    for(var i=0;i<errors.length;i++) {
      if(errors[i].field==name) {
        error = errors[i];
        break;
      }
    }


     return(<div className="mb-3" key={"form"+type+key}>
      <Form.Group className="" controlId={"controlForm"+type+key}>
        <Form.Label>{label}</Form.Label>
        <textarea readOnly={readOnly} name={name} className={"form-control "+props.theme+' textarea '+(readOnly?'readonly':'')} rows={textareaArgs.rows} placeholder={placeholder} defaultValue={defaultValue}></textarea>
      </Form.Group>
      <Form.Text className="text-muted">
        {mutedText} <a href={mutedLink?mutedLink.href:''}>{mutedLink?mutedLink.label:''}</a> <span className="form_error_message">{error?error.description:''}</span>
      </Form.Text>
      </div>);
  }


    useEffect(() => {

      if(!doOnce) {
        doOnce=true;
       
      }
        


    });





  return (
    <> 
    <div className="form_left">

      <div id="first_error"></div>
      {errors.map((error, key) => {
        if(!error.field) {
          return (
            <div key={key}>
              <br/>
              <span className="an_error"><h5>{error.label}</h5></span>
              <div className="small_error_text"><span className="an_error">*</span> {error.description} <span className={(error.redirect_url?'':'hidden')}><a href={error.redirect_url}>{error.link_label}</a></span>
              <span className="an_error">*</span></div><br/>
            </div>
          );
        }
      })}

  
      <Form id={'actionform_'+props.id} action={props.submitAction} method={props.method?props.method:"post"}>

      {props.fields.map((field, key) => {


        return eval(field.type+'(key, field.type, field.name, field.label, field.mutedText, field.mutedLink, field.placeholder, field.defaultValue, field.readOnly, field.disabled, field.dateArgs, field.selectOpts, field.textareaArgs, field.changed)');

      })}



      <Button id={'actionform_'+props.id+'_submit'} className={(props.submitLabel?'':'hidden')} variant="primary" size={props.buttonSize} onClick={()=>postForm()}>
        {props.submitLabel}
      </Button>
      <Button className={"shift_back_button_on_form "+(props.backLabel?'':'hidden')} href={props.backAction} variant="warning" size={props.buttonSize} active>{props.backLabel}</Button>
      </Form>
    </div>
    </>);

}
  
export default ActionForm;