import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';
//import Carousel from 'react-bootstrap/Carousel';

import * as funcs from './../funcs.js';

import { motion, useAnimate } from "framer-motion"



function Services(props) {



  var [render, setRender] = useState(0);

  
  useEffect(() => {




  });


  

  return (
    <div className="page">
    <div className="vector_truck">
      <h1>Our Services</h1>
      <p>Learn more about the service areas in which we specialize.</p><br/>
      <Button variant="danger" size="lg" onClick={()=>{window.open("/quote", "_self")}}><div className="white_freight_img"></div><div className="button_text_with_img">Get a Quote</div></Button>
     </div>



     {vars.services.map((aService, index)=>{


       return (
          <div className="service_block" style={{zIndex: (47-index), backgroundImage: 'linear-gradient(45deg, rgba(19,37,82, 0.75), rgba(19,37,82, 0.85)), url("/images/services/'+aService.id+funcs.randomServiceImg(aService.images)+'.jpg")'}} key={index}>
            <div className="service_icon"><a className="white_link" href={"/services/"+aService.id}><img className="make_white" src={"/images/icons/"+aService.id+".png"} width="90px" /></a></div>
            <div className="service_desc"><h2><a className="white_link" href={"/services/"+aService.id}>{aService.name}</a></h2><br/>
            <p>{aService.shortDesc}</p></div>
            <br/> <br/> 
          </div>
        );
       })}

    

     
     

     

     


      <div className="two_button_ai_landscape" style={{zIndex: 10, textAlign: 'center', padding: '36px'}}>
     <div className="left_service"><Button variant="primary" size="lg" onClick={()=>{window.open("tel://1.306.789.5055", "_self")}}><div className="white_call_img"></div><div className="button_text_with_img">Call 1.306.789.5055</div></Button></div>
     
     <div className="right_service"><Button variant="danger" size="lg" onClick={()=>{window.open("/quote", "_self")}}><div className="white_freight_img"></div><div className="button_text_with_img">Get a Quote</div></Button></div>
     </div>
    </div>);

}
  
export default Services;