import { ReactSession } from 'react-client-session';

import * as vars from './vars.js';

import { addDays, subDays, getDay } from 'date-fns';


async function get(endpoint, body, errors) {

	var response, data;

	var encodedBody={};
	for(const [key, value] of Object.entries(body)) {
		encodedBody[key] = encodeURIComponent(value);
	}

	var params = new URLSearchParams(encodedBody).toString();

	try {

		response = await fetch(vars.apiUrl+endpoint+'?'+params, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		});
		data = await response.json();

		//console.log(data);

	} catch(error) {

		console.log('Getting '+endpoint+'?'+params+' failed entirely.'+error);
		errors([
			{
				description: 'Getting '+endpoint+'?'+params+' failed entirely.'+error
			}]);
		return false;

	};
	

	if(data.errors) {
		console.log('Getting '+endpoint+' return errors from API.');
		for(var i=0;i<data.errors.length;i++) {
	  		console.log(data.errors[i].description);
	  	}
		errors(data.errors);
		return false;
	}

	console.log('Get '+endpoint);
	console.log(data);

	return data;

}


async function post(endpoint, body, errors) {


	var response, data;

	try {

		response = await fetch(vars.apiUrl+endpoint, {
			method: 'POST',
	      	headers: { 'Content-Type': 'application/json' }, //multipart/form-data
	      	//headers: { 'Content-Type': 'multipart/form-data' }, //
	      	body: JSON.stringify(body)
		});
		data = await response.json();

		//console.log(data);

	} catch(error) {

		console.log('Posting to '+endpoint+' failed entirely.'+error);
		errors([
			{
				description: 'Posting to '+endpoint+' failed entirely. '+error
			}]);
		return false;

	}

	if(data.errors) {
		console.log('Posting to '+endpoint+' returned errors from API.');
		for(var i=0;i<data.errors.length;i++) {
	  		console.log(data.errors[i].description);
	  	}
		errors(data.errors);
		return false;
	}

	console.log('Post to '+endpoint);
	console.log(data);

	return data;

}


function randomServiceImg(num) {
	var num = Math.floor(Math.random() * num) + 1;

	if(num==1) {
		return '';
	}

	return num;
}



export {
	get,
	post,
	randomServiceImg
}