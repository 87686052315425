import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';
//import Carousel from 'react-bootstrap/Carousel';

import { motion, useAnimate } from "framer-motion"



function About(props) {


  var [render, setRender] = useState(0);

  
  useEffect(() => {




  });


  

  return (
    <div className="page">
    <div className="ai_landscape_medium">

    
      </div>
     <div className="white_block">
     <h1>About Action Express</h1><br/>
      <p>Established in 1988 and headquartered in Regina, Saskatchewan, Action Express started out with 2 vehicles, and quickly grew to a fleet of more than 55!</p><br/>
      <p>It now employs nearly 80 individuals and contractors across the province, has a 10,000 square-foot facility, and staffs long-haul dispatchers, courier dispatchers, and 4 full-time mechanics.</p><br/>
      <h3>Action Express is still proudly locally owned and operated.</h3><br/>
     </div>



    </div>);

}
  
export default About;