import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';
//import Carousel from 'react-bootstrap/Carousel';

import * as funcs from './../funcs.js';

import { motion, useAnimate } from "framer-motion"

import {
  useParams
} from "react-router-dom";

function Service(props) {

  let { service_id } = useParams();

  var service;
  for(var i=0;i<vars.services.length;i++) {
    if(vars.services[i].id == service_id) {
      service = vars.services[i];
      break;
    }
  }


  var [render, setRender] = useState(0);
  let[htmlFileString, setHtmlFileString] = useState();

  var rsi = funcs.randomServiceImg(service.images);

  var rsi2 = rsi + 1;
  if(rsi=='') {
    rsi2=2;
  }
  if(rsi >= service.images) {
    rsi2 = '';
  }
  
  async function fetchHtml() {
    setHtmlFileString(await (await fetch('/html/'+service.id+'.html')).text());
    console.log(htmlFileString);
  }

  useEffect(() => {

    fetchHtml();

  });


  

  return (
    <div className="page">
    <div className="service_block" style={{height:'200px', zIndex: 48, backgroundImage: 'linear-gradient(45deg, rgba(19,37,82, 0.65), rgba(19,37,82, 0.85)), url("/images/services/'+service.id+rsi+'.jpg")'}}>
    
      <div className="left_service"><h1><img className="make_white" src={"/images/icons/"+service.id+".png"} width="40px" /> {service.name}</h1></div>
      <div className="right_service"><Button variant="danger" size="lg" onClick={()=>{window.open("/quote/"+service.prefill_id, "_self")}}><div className="white_freight_img"></div><div className="button_text_with_img">Get a Quote</div></Button></div>
     </div>


    <div className="blue_block" style={{zIndex: 47}}>
      <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div><br/>
      <Button variant="secondary" size="lg" onClick={()=>{window.open("/services", "_self")}}><div className="white_services_img"></div><div className="button_text_with_img">Back to Services</div></Button>
      <br/>
    </div>
    <div className="service_block" style={{height:'500px', zIndex: 46, backgroundImage: 'url("/images/services/'+service.id+rsi2+'.jpg")'}}>
    </div>


      <div className="two_button_blue_block" style={{zIndex: 10,textAlign: 'center'}}>
     <div className="left_service"><Button variant="primary" size="lg" onClick={()=>{window.open("tel://1.306.789.5055", "_self")}}><div className="white_call_img"></div><div className="button_text_with_img">Call 1.306.789.5055</div></Button></div>
     
     <div className="right_service"><Button variant="danger" size="lg" onClick={()=>{window.open("/quote/"+service.prefill_id, "_self")}}><div className="white_freight_img"></div><div className="button_text_with_img">Get {service.aOrAn} {service.name} Quote Today</div></Button></div>
     </div>
    </div>);

}
  
export default Service;