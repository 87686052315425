import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import * as funcs from './../funcs.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { motion, useAnimate } from "framer-motion"
import ActionForm from './../components/ActionForm';

import { ReactSession } from 'react-client-session';

import { addDays, subDays, getDay } from 'date-fns';

import {
  useParams
} from "react-router-dom";

var parseOnce=false;

var prefill_message;

function Quote(props) {

  let { prefill_id } = useParams();



  var [prefillMessage, setPrefillMessage] = useState('Any additional questions? Feel free to add any other helpful information.');

  

  var [deliveryDate, setDeliveryDate] = useState(new Date());

  var [render, setRender] = useState(0);

  

   ReactSession.setStoreType("localStorage");


   //ReactSession.set("next_url", errors[0].next_url);

  useEffect(() => {


    for(var i=0;i<vars.services.length;i++) {
      if(vars.services[i].prefill_id == prefill_id) {
        prefill_message = vars.services[i].prefill_message;
        break;
      }
    }
    for(var i=0;i<vars.fleet.length;i++) {
      if(vars.fleet[i].prefill_id == prefill_id) {
        prefill_message = vars.fleet[i].prefill_message;
        break;
      }
    }
    setPrefillMessage(prefill_message);


    if(!parseOnce) {
      parseOnce=true;
      const queryParams = new URLSearchParams(window.location.search);
    }
    


  });


  

  return (
    <div className="page">
     <div className="white_block">
     <h1>Quote</h1>
     <p>Receive a free quote.</p>
     <br/><br/>
      <ActionForm theme="dark" 
              nextReason=""
              submitLabel="Get a Quote" submitAction={"/freight/quote"}
              backLabel="" backAction="" 
              buttonSize="lg" 
              fields={[
                { type: 'text', name: 'name', label: 'Your Name',  placeholder: 'John Smith', defaultValue: '', readOnly: false},
                { type: 'email', name: 'email_address', label: 'Email Address',  placeholder: 'john.smith@gmail.com', defaultValue: '', readOnly: false},
                { type: 'text', name: 'originating_address', label: 'Originating Address',  placeholder: 'Vancouver', defaultValue: '', readOnly: false},
                { type: 'text', name: 'destination_address', label: 'Destination Address',  placeholder: 'Montreal', defaultValue: '', readOnly: false},
                
                { type: 'date', hideIfFieldValue: false, name: 'delivery_date', label: 'Delivery Date', defaultValue: '', readOnly: false, dateArgs: {excludeDates: [], filterDate: false, startDate: deliveryDate, onChange: (date:Date) => { console.log('test '+date); setDeliveryDate(date); } }},

                 { type: 'text', name: 'weight', label: 'Weight',  placeholder: '30,000 lbs', defaultValue: '', readOnly: false},
                 { type: 'text', name: 'dimensions', label: 'Dimensons WxLxH',  placeholder: '8ft x 40ft x 8ft', defaultValue: '', readOnly: false},

                { type: 'textarea', name: 'additional_info', label: 'Additional Information',  placeholder: 'Additional information', defaultValue: prefill_message, readOnly: false, textareaArgs: {rows: 3}},
                { type: 'hidden', name: 'redirect_url', defaultValue: '/', readOnly: true},
                { type: 'hidden', name: 'redirect_label', defaultValue: 'Home', readOnly: true}
              ]} />

      <br/><br/>

      
     </div>
    </div>);

}
  
export default Quote;