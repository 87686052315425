import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';
//import Carousel from 'react-bootstrap/Carousel';

import { motion, useAnimate } from "framer-motion"


var randTruck=vars.fleet[Math.floor(Math.random() * vars.fleet.length)];
var currentTruckName = randTruck.name;

var lockRender;
function Home(props) {

  const [randomFleetTruck, setRandomFleetTruck] = useState(randTruck);

  var [render, setRender] = useState(0);

  var isScrolling;

  const [scope, animate] = useAnimate();


  var [landscapeNum, setLandscapeNum] = useState(Math.ceil(Math.random() * (4)+1));


  
  
  function setNewTruck() {
    do {
      randTruck = vars.fleet[Math.floor(Math.random() * vars.fleet.length)];
      console.log(currentTruckName+' : '+randTruck.name);
    } while(randTruck.name == currentTruckName);
    
    currentTruckName=randTruck.name;
    setRandomFleetTruck(randTruck);
  }

  
  useEffect(() => {


     window.addEventListener('scroll', e => {
        clearTimeout(isScrolling);
        isScrolling = setTimeout(() => {
          doneScrolling();
        }, 500);
      },
      false
    );


  });


  function doneScrolling() {

    if(!lockRender) {
      lockRender=true;
      animate(scope.current, { x: -2000 }, {
            type: "spring",
            duration: 0.5
          });
      setTimeout(()=>{
        setNewTruck();
        animate(scope.current, { x: 2000 }, {
              duration: 0.0
            });
          setTimeout(()=>{
            animate(scope.current, { x: 0 }, {
                type: "spring",
                duration: 0.5
              });
          }, 100);
      }, 500);

      setTimeout(()=>{
        lockRender=false;
      }, 1300);
    }
    
    
  }

  

  return (
    <div className="page">
      <div className={"ai_landscape"+landscapeNum}>

        <div className="transparent_icons">
          <div className="us_label" onClick={()=>{window.open("/services", "_self")}}><div className="our_services_trans_icon"></div><a className="white_link">Our Services</a></div>
          <div className="us_label" onClick={()=>{window.open("/fleet", "_self")}}><div className="our_fleet_trans_icon"></div><a className="white_link">Our Fleet</a></div>
          <div className="us_label" onClick={()=>{window.open("/careers", "_self")}}><div className="drive_for_us_trans_icon"></div><a className="white_link">Drive For Us</a></div>
          <div className="us_label_last" onClick={()=>{window.open("/contact", "_self")}}><div className="find_us_trans_icon"></div><a className="white_link">Find Us</a></div>
        </div>

      </div>
      <div className="vector_truck">
        <div className="freight_since">
      
        </div>
      </div>
      <div className="fleet_box">
        <div className="fleet_text">

          <h6>Our Fleet</h6>
          <h3>{randomFleetTruck.name}</h3>
          <p>{randomFleetTruck.use}</p>
          <h5>{randomFleetTruck.weight}</h5>
        </div>
          <motion.div ref={scope}
          initial={{ x: 2000 }}
          animate={{ x: 0 }}
          transition={{
            type: "spring",
            duration: 0.5
          }}
          exit={{x: -2000}}>
            <div className="truck_box" style={{backgroundImage: 'url("images/fleet/'+randomFleetTruck.image+'")'}}>
            </div>
          </motion.div>
      </div>


    </div>);

}
  
export default Home;