import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';
//import Carousel from 'react-bootstrap/Carousel';

import { motion, useAnimate } from "framer-motion"



function Fleet(props) {


  var [render, setRender] = useState(0);

  
  useEffect(() => {




  });


  

  return (
    <div className="page">
    <div className="vector_truck">
      <h1>Our Fleet</h1><br/><br/>
      <Button variant="danger" size="lg" onClick={()=>{window.open("/quote", "_self")}}><div className="white_freight_img"></div><div className="button_text_with_img">Get a Quote</div></Button>
     </div>
     <div className="blue_block">
     <h2>Heavy Fleet / Long-Haul Trucking</h2><br/>
     <p>Action Express provides flatdeck freight and long-haul trucking services across Canada.</p><br/>
     </div>


      {vars.fleet.map((aTruck, index)=>{
          //console.log(aPkg+' + '+index);
        var isDarker='fleet_box_blue';

        if(index%2==0) {
          isDarker='fleet_box_blue_lighter';
        }

           return (
              <div className={"fleet_box "+isDarker} style={{zIndex: (47-index)}} key={index}>
                <div className="fleet_text">
                  
                  <h3>{aTruck.name}</h3>
                  <p>{aTruck.use}</p>
                  <h4><i>{aTruck.weight}</i></h4>

                  <a className={"white_link "+(aTruck.hasDatasheet ? '' : 'hidden')} target="_blank" href={"/images/datasheets/"+aTruck.id+".jpg"}><img className="make_white" src="images/icons/datasheet.png" width="20px" /> View Datasheet</a>
                  
                </div>
                <motion.div
                  initial={{ x: 1000 }}
                  animate={{ x: 0 }}
                  transition={{
                    type: "spring",
                    duration: (0.75 + index/20)
                  }}>
                <div className="truck_box" style={{backgroundImage: 'url("images/fleet/'+aTruck.image+'")'}}>

                </div>
                </motion.div>
              </div>
            );
       })}


      <div className="ai_landscape_short" style={{zIndex: 10, textAlign: 'center'}}>
     <Button variant="primary" size="lg" onClick={()=>{window.open("tel://1.306.789.5055", "_self")}}><div className="white_call_img"></div><div className="button_text_with_img">Call about our Heavy Fleet today</div></Button><br/>
     </div>
    </div>);

}
  
export default Fleet;