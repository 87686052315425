import React, {useState, useEffect} from 'react';

import { Link } from "react-router-dom";
import * as vars from './../vars.js';
import Button from 'react-bootstrap/Button';
//import Carousel from 'react-bootstrap/Carousel';

import ActionForm from './../components/ActionForm';

import { ReactSession } from 'react-client-session';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
const google = window.google;

function Contact(props) {


  var [render, setRender] = useState(0);

  ReactSession.setStoreType("localStorage");
  useEffect(() => {


    // Initialize and add the map
    let map;

    async function initMap() {
      const position = { lat: 50.46458146007856, lng: -104.57776444443024 };
      // Request needed libraries.
      //@ts-ignore
      const { Map } = await google.maps.importLibrary("maps");
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

      // The map, centered at Uluru
      map = new Map(document.getElementById("map"), {
        zoom: 14,
        center: position,
        mapId: "ActionExpressHQ_Map",
      });

      // The marker, positioned at Uluru
      const marker = new AdvancedMarkerElement({
        map: map,
        position: position,
        title: "AE_HQ",
      });
    }

    initMap();

    // Initialize and add the map
    let map2;

    async function initMap2() {
      const position = { lat: 50.46458146007856, lng: -104.57776444443024 };
      // Request needed libraries.
      //@ts-ignore
      const { Map } = await google.maps.importLibrary("maps");
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

      // The map, centered at Uluru
      map2 = new Map(document.getElementById("map2"), {
        zoom: 14,
        center: position,
        mapId: "ActionExpressHQ_Map",
      });

      // The marker, positioned at Uluru
      const marker = new AdvancedMarkerElement({
        map: map2,
        position: position,
        title: "AE_HQ",
      });
    }

    initMap2();

  });


  

  return (
    <div className="page">
      <div className="vector_truck">
        <h1>Contact Us</h1><br/>
        
      </div>
      <div id="fullweb_contact_bb" className="blue_block">
       <div className="fifty_percent">

        <h3>Regina Headquarters:</h3>
        <p>836 Fleury Street</p>
          <p>Regina, SK</p>
          <p>S4N 4W6</p><br/>

          

          <h3>Saskatoon Branch:</h3>
          <p>3250 Idylwyld Drive N</p>
          <p>Saskatoon, SK</p>
          <p>S7L 5Y7</p><br/>
          <h4>Open 7AM to 5PM, Monday to Friday</h4><br/>

          <h3>PH. <a className="white_link" href="tel://1-306-789-5055">1.306.789.5055</a></h3>
          <h3>FX. <a className="white_link" href="fax://1-306-789-5599">1.306.789.5599</a></h3><br/>
          <h3><a  className="white_link" href="mailto:info@actionexpress.co">info@actionexpress.co</a></h3>
        </div>
        <div className="fleet_box_blue_lighter fifty_percent" >
          <div id="map">
          </div>
        </div>
      </div>

      <div id="mobile_contact_bb" >
       <div className="blue_block">

        <h3>Regina Headquarters:</h3>
        <p>836 Fleury Street</p>
          <p>Regina, SK</p>
          <p>S4N 4W6</p><br/>
          <h2>PH. <a className="white_link" href="tel://1-306-789-5055">1.306.789.5055</a></h2><br/>

          

          <h3>Saskatoon Headquarters:</h3>
          <p>3250 Idylwyld Drive N</p>
          <p>Saskatoon, SK</p>
          <p>S7L 5Y7</p><br/>
          <h2>PH. <a className="white_link" href="tel://1-844-706-7202">1.844.706.7202</a></h2><br/>

          <br/><h4>Open 7AM to 5PM, Monday to Friday</h4><br/>

          
          <h2>FX. 1.306.789.5055</h2>
          <h3><a  className="white_link" href="mailto:info@actionexpress.co">info@actionexpress.co</a></h3>
        </div>
        <div className="white_block no_padding">
          <div id="map2">
          </div>
        </div>
      </div>

      <div className="red_block send_message">
        <h2>Send us a Message</h2><br/>

        <ActionForm theme="dark" 
          nextReason=""
          submitLabel="Send" submitAction={"/contact/send_message"}
          backLabel="" backAction="" 
          buttonSize="lg" 
          fields={[
            { type: 'text', name: 'name', label: 'Your Name',  placeholder: 'John', defaultValue: '', readOnly: false},
            { type: 'email', name: 'email_address', label: 'Email Address',  placeholder: 'john.smith@gmail.com', defaultValue: '', readOnly: false},
            { type: 'textarea', name: 'the_message', label: 'Message',  placeholder: '', defaultValue: '', readOnly: false, textareaArgs: {rows: 3}},
            { type: 'hidden', name: 'redirect_url', defaultValue: ReactSession.get("next_url"), readOnly: true}
          ]} />
      </div>



    </div>);

}
  
export default Contact;